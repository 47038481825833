import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, FormControlLabel, FormGroup, Switch, TextField, Typography } from "@mui/material";
import { useOrganisation, useOrganisationDispatch, OrganisationActionTypes } from "../../state/organisationstate";
import { Apis } from "../../api/apis";
import { LoaderButton } from "../common/LoaderButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectCreateModel } from "../../api/apimodels";

function CreateProjectForm() {
  const { projects } = useOrganisation();
  const dispatch = useOrganisationDispatch();
  const [name, setName] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [defaultContent, setDefaultContent] = useState(true);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const api = Apis.shared().metadata;

  async function onSubmit() {
    setLoading(true);
    try {
      const createData: ProjectCreateModel = { name, description, subtitle };
      const result = await api.createProject(createData, defaultContent);
      dispatch({ type: OrganisationActionTypes.setProjects, projects: [result, ...projects] });
      setName("");
      setSubtitle("");
      setDescription("");
      navigate(location, { replace: true });
    } catch (e) {
      console.error("Create project failed", e);
    }
    setLoading(false);
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          "& .MuiTypography-root": { marginTop: 1, marginBottom: 2, width: "100%" },
          "& .MuiTextField-root": { marginBottom: 1, width: "100%" },
          "& .MuiButton-root": { marginTop: 1, alignSelf: "flex-end" },
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h6">Create new project</Typography>
        <TextField
          id="create-project-name"
          label="Project name"
          variant="filled"
          value={name}
          disabled={loading}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
        />
        <TextField
          id="create-project-subtitle"
          label="Project subtitle (e.g. question)"
          variant="filled"
          value={subtitle}
          disabled={loading}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSubtitle(event.target.value);
          }}
        />
        <TextField
          id="create-project-description"
          label="Project description"
          multiline
          rows={4}
          variant="filled"
          value={description}
          disabled={loading}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDescription(event.target.value);
          }}
        />
        <FormGroup sx={{flexGrow: 1, pl: 2, my: 1}}>
          <FormControlLabel control={<Switch checked={defaultContent} onChange={() => setDefaultContent(!defaultContent)}/>} label="Include default models" sx={{ "& .MuiFormControlLabel-label": { my: 0, opacity: 0.85 }}}/>
        </FormGroup>
        <LoaderButton loading={loading} title="Create" onClick={onSubmit} variant="outlined" color="secondary" />
      </Box>
    </Paper>
  );
}

export default CreateProjectForm;
