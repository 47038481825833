import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import * as PageInfo from "../PageInfo";
import { ProjectModel } from "../../api/apimodels";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import ResourceList from "../../components/common/ResourceList";
import { CreateDatasetForm } from "../dataset/DatasetPage";
import { CreateProcessorForm } from "../processor/ProcessorsPage";
import { CreateExplorationsForm } from "../explorations/ExplorationsPage";
import { sortedResource } from "../../util/sorting";
import { StandardCard } from "../../components/common/StandardCard";
import { Apis } from "../../api/apis";

type EditProjectInfoDialogProps = {
  open: boolean;
  dialogTitle: string;
  name?: string;
  subtitle?: string;
  description?: string;
  canDelete: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSave: (name: string, subtitle: string, description: string) => void;
}

export function EditProjectInfoDialog(props: EditProjectInfoDialogProps) {
  const { open, onClose, onSave, canDelete, onDelete, dialogTitle, name, subtitle, description } = props;
  const [projectName, setProjectName] = useState(name ?? "");
  const [projectSubtitle, setProjectSubtitle] = useState(subtitle ?? "");
  const [projectDescription, setProjectDescription] = useState(description ?? "");
  useEffect(() => {
    if (open) {
      setProjectName(name ?? "");
      setProjectDescription(description ?? "");
    } else {
      setProjectName("");
      setProjectName("");
    }
  }, [open, name, description]);

  return (
      <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  onSave(projectName, projectSubtitle, projectDescription);
              },
          }}
      >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              <TextField required margin="dense" id="name" name="name" label="Name" type="text" fullWidth value={projectName} onChange={(e) => { setProjectName(e.target.value); }} variant="standard" />
              <TextField margin="dense" id="subtitle" name="subtitle" label="Subtitle" type="text" fullWidth value={projectSubtitle} onChange={(e) => { setProjectSubtitle(e.target.value); }} variant="standard" multiline />
              {(projectDescription ?? '').length > 0 && (<TextField margin="dense" id="description" name="description" label="Description" type="text" value={projectDescription} onChange={(e) => { setProjectDescription(e.target.value); }} variant="standard" fullWidth multiline />)}
          </DialogContent>
          <DialogActions>
              <Box sx={{flexGrow: 1, ml: 1}}>
                {canDelete && (
                  <Button onClick={onDelete} color="error">Delete project</Button>
                )}
              </Box>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Save</Button>
          </DialogActions>
      </Dialog>
  );
}


const LabelAndText: FC<{label: string, text: string, sx?: SxProps<Theme>}> = ({label, text, sx}) => {
  return (
    <Stack direction="column" sx={sx}>
      <Typography variant="subtitle2" sx={{opacity: 0.75}}>{label}</Typography>
      <Typography variant="body1">{text}</Typography>
    </Stack>
  )
}

type ProjectInfoProps = {
  project: ProjectModel;
  onEdit: () => void;
  sx?: SxProps<Theme>;
};

const ProjectInfo: FC<ProjectInfoProps> = ({ project, onEdit, sx }) => {
  const { subtitle, description } = project;
  return (
    <StandardCard sx={{...sx, p: 3}}>
      <Stack sx={{ pb: 2 }} direction="row" justifyContent="space-between">
        <Typography variant="h6">
          Project info
        </Typography>
        <Button variant="text" onClick={onEdit}>Edit</Button>
      </Stack>
      <Stack direction="column" gap={1}>
        <LabelAndText label="Subtitle" text={subtitle ?? ""} sx={{mb: 1}} />
        {(description ?? '').length > 0 && (<LabelAndText label="Description" text={description ?? ""} sx={{mb: 1}} />)}
      </Stack>
    </StandardCard>
  );
};

export default function ProjectPage() {
  const projectMeta = useRouteLoaderData("project") as ProjectModel;
  const navigate = useNavigate();
  const enabledPages = new Set([PageInfo.ExplorationPage.slug, PageInfo.DatasetPage.slug]);
  const latestExplorations = useMemo(() => (sortedResource(projectMeta.latest_explorations ?? [], true)), [projectMeta]);
  const latestDatasets = useMemo(() => (sortedResource(projectMeta.latest_datasets ?? [], true)), [projectMeta]);
  const maxItems = 5 - 1;  // 5 is the default limit, one less to check if we need to show the "view all" button
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  
  function onSelectDataset(id: number) {
    navigate("./dataset/" + id);
  }

  function onSelectModel(id: number) {
    navigate("./processor/" + id);
  }

  function onSelectExploration(id: number) {
    navigate("./exploration/" + id);
  }

  function onViewAllExplorations() {
    navigate("./exploration");
  }

  function onViewAllDatasets() {
    navigate("./dataset")
  }

  function onViewAllProcessors() {
    navigate("./processor")
  }

  function onSaveProjectInfo(name: string, subtitle: string, description: string) {
    Apis.shared().metadata.updateProject(projectMeta.id!, name, subtitle, description)
      .then(() => {
        navigate(".");
        setEditDialogOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function onDeleteProject() {
    Apis.shared().metadata.deleteProject(projectMeta.id!)
      .then(() => {
        navigate("/");
        setEditDialogOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <Box sx={{ display: "flex", "& .MuiCard-root": { mt: 0, mb: 2, width: "100%" } }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <ProjectInfo project={projectMeta} onEdit={() => { setEditDialogOpen(true); }}/>
                {enabledPages.has(PageInfo.ExplorationPage.slug) && latestExplorations.length > 0 && (
                  <Box sx={{mb: 2}}>
                    <ResourceList
                      onSelectResource={onSelectExploration}
                      icon={PageInfo.ExplorationPage.menuIcon}
                      title="Latest explorations"
                      items={latestExplorations}
                      isLoadingFn={(item) => (false)}
                      createLabel="Create new exploration"
                      forceExpand={latestExplorations.length === 0}
                      maxItems={maxItems}
                      onViewAll={onViewAllExplorations}
                    />
                  </Box>
                )}
                {enabledPages.has(PageInfo.DatasetPage.slug) && latestDatasets.length > 0 && (
                  <Box sx={{mb: 2}}>
                    <ResourceList
                      onSelectResource={onSelectDataset}
                      icon={PageInfo.DatasetPage.menuIcon}
                      title="Latest datasets"
                      items={latestDatasets}
                      isLoadingFn={(item) => (false)}
                      createLabel="Create new dataset"
                      forceExpand={latestDatasets.length === 0}
                      maxItems={maxItems}
                      onViewAll={onViewAllDatasets}
                    />
                  </Box>
                )}
              </Grid>
            <Grid item xs={12} md={4} lg={4}>
              {enabledPages.has(PageInfo.ExplorationPage.slug) && latestDatasets.length > 0 && <CreateExplorationsForm />}
              {enabledPages.has(PageInfo.DatasetPage.slug) && latestDatasets.length === 0 && <CreateDatasetForm />}
              {enabledPages.has(PageInfo.ProcessorPage.slug) && <CreateProcessorForm />}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <EditProjectInfoDialog 
        open={editDialogOpen} 
        dialogTitle={"Edit Project"}
        name={projectMeta.name}
        subtitle={projectMeta.subtitle}
        description={projectMeta.description}
        onClose={() => {setEditDialogOpen(false)}} 
        canDelete={projectMeta.deletable}
        onDelete={onDeleteProject} 
        onSave={onSaveProjectInfo} 
        />
    </Box>
  );
}
